export enum PlanListPresetId {
  default = 'variants-lhrjp3681',
  default_no_image = 'variants-lvm8ya5a',
  default_fb = 'variants-ltoj5phf',
  default_tt_d = 'variants-lucxumkf',
  default_fb_tt_d = 'variants-lucxz9px',
  horizontal = 'variants-lm0a2lwk',
  horizontal_no_image = 'variants-lvm8zsiv',
  horizontal_fb = 'variants-ltpfdt8c',
  horizontal_tt_d = 'variants-lucxqqxr',
  horizontal_fb_tt_d = 'variants-lucxs7eg',
}
