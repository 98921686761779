import { ComponentRef } from '@wix/editor-platform-sdk-types/dist/types/common';
import { EditorSDK } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { changeImageHeight } from '../components/Plan/panels/Layout/utils';
import { PlanListWidgetProps } from '../components/PlanListWidget/model';
import {
  isHorizontalPlanListPreset,
  isValidPlanListPresetId,
  isValidSinglePlanPresetId,
  MOBILE_PRESET_BY_PLAN_LIST_DESKTOP_PRESET_ID,
  MOBILE_PRESET_BY_SINGLE_PLAN_DESKTOP_PRESET_ID,
  PLAN_PRESETS,
} from '../layout-config';
import { getContainerRef, getRootWidget, setWidgetProps } from '../utils/widget';
import { biReportPresetChange } from './manifest-utils';

export async function onGlobalDesignPresetChanged(params: {
  eventPayload: { preset: string; componentRef: ComponentRef };
  editorSDK: EditorSDK;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { eventPayload, editorSDK, flowAPI } = params;
  const { preset, componentRef } = eventPayload;

  // TODO: Add BI event
  if (isValidPlanListPresetId(preset)) {
    const [parent] = await editorSDK.components.getAncestors('', { componentRef });
    await editorSDK.components.refComponents.removeAllOverrides('', {
      componentRef: parent,
    });
    editorSDK.document.application.appStudioWidgets.changePreset('', {
      componentRef: await getContainerRef(editorSDK, componentRef),
      layoutPresetId: MOBILE_PRESET_BY_PLAN_LIST_DESKTOP_PRESET_ID[preset],
      stylePresetId: MOBILE_PRESET_BY_PLAN_LIST_DESKTOP_PRESET_ID[preset],
      context: {
        viewport: 'MOBILE',
      },
    });
    setWidgetProps<PlanListWidgetProps>({
      editorSDK,
      widgetRef: componentRef,
      newProps: { isHorizontal: isHorizontalPlanListPreset(preset) },
    });
  }

  if (isValidSinglePlanPresetId(preset)) {
    const rootWidget = await getRootWidget(editorSDK, componentRef);
    const rootWidgetParentRef = await getContainerRef(editorSDK, rootWidget);
    const presetConfig = PLAN_PRESETS[preset];
    const isFullWidth = await editorSDK.components.isFullWidth('', { componentRef: rootWidgetParentRef });
    /*
    Changing image height manually, because blocks platform
    has a bug, where design variable change affects all PLAN_PRESETS
    */
    await changeImageHeight({
      editorSDK,
      widgetRef: componentRef,
      height: presetConfig.layoutDefaults.imageHeight,
    });

    if (!isFullWidth) {
      editorSDK.document.components.layout.update('', {
        componentRef: rootWidgetParentRef,
        layout: {
          width: presetConfig.width,
        },
      });
    }
    editorSDK.document.application.appStudioWidgets.changePreset('', {
      componentRef: await getContainerRef(editorSDK, componentRef),
      layoutPresetId: MOBILE_PRESET_BY_SINGLE_PLAN_DESKTOP_PRESET_ID[preset],
      stylePresetId: MOBILE_PRESET_BY_SINGLE_PLAN_DESKTOP_PRESET_ID[preset],
      context: {
        viewport: 'MOBILE',
      },
    });

    biReportPresetChange({ editorSDK, rootWidget, flowAPI, presetId: preset });
  }
}
